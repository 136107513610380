import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { getStatusCode } from '~/services/utils/error';
import { NoSavedSessionError } from './services/api/auth';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const statusCode = getStatusCode(error);
        console.log(`query retry failureCount:${failureCount} error:${statusCode}`);
        if (statusCode === 401 || statusCode === 404 || statusCode === 503) {
          return false; // Do not retry
        }
        // Retry for other types of errors
        return failureCount < 3;
      },
      throwOnError: (error) => {
        const statusCode = getStatusCode(error);
        if (statusCode === 401 || statusCode === 404 || statusCode === 503) {
          // Throw error which will be caught by the ErrorBoundary
          return true;
        }
        // Other errors
        if (error instanceof NoSavedSessionError) {
          console.log(error.message);
        } else {
          console.error('Query Error:', error);
          captureException(error);
        }

        return false;
      },
    },
  },
});
