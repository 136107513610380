import { AtpSessionData } from '@atproto/api';
import { LOCAL_STORAGE_SESSION_KEY } from '~/config/robinfeed';
import { queryClient } from '~/queryClient';
import { atpClient } from './agent';

export const logout = async () => {
  console.log('Logging out...');
  clearSession();
  await atpClient.agent.logout().catch(() => {});
  queryClient.clear();
  console.log('Logged out successfully.');
};

export const throwSessionError = () => {
  throw new Response(null, {
    status: 401,
    statusText: 'No session available. Please log in.',
  });
};

const decodeJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.error('Failed to parse JWT token:', e);
    return null;
  }
};

const clearSession = () => {
  localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
};

const getSessionExpiryDate = (session: AtpSessionData): number => {
  // Get the expiry date of the session in milliseconds
  return decodeJWT(session.accessJwt).exp * 1000;
};

export const getSessionFromLocalStorage = () => {
  return typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_SESSION_KEY) || 'null') : null;
};

export const saveSessionToLocalStorage = () => {
  if (!atpClient.agent.session) return;
  localStorage.setItem(
    LOCAL_STORAGE_SESSION_KEY,
    JSON.stringify({
      ...atpClient.agent.session,
      service: atpClient.agent.serviceUrl.toString(),
    }),
  );
  console.log('Session saved to local storage.');
};

const sessionIsExpiring = (): boolean => {
  if (typeof window === 'undefined') return false;
  // Return true if session is null or if it's expiring in less than 10 mins
  return !atpClient.agent.session || getSessionExpiryDate(atpClient.agent.session) - Date.now() < 10 * 60 * 1000;
};

export class NoSavedSessionError extends Error {
  constructor(message = 'No saved session available') {
    super(message);
    this.name = 'NoSavedSessionError';
  }
}

export const getAgentSession = async ({ forceRefresh = false } = {}) => {
  if (!forceRefresh && !sessionIsExpiring()) return atpClient.agent.session; // session is valid

  const savedSession = getSessionFromLocalStorage();
  if (!savedSession || (savedSession && !savedSession.service)) throw new NoSavedSessionError();

  // Try to resume the session. resumeSession() refreshes the session if it's expired
  try {
    const response = await atpClient.agent.resumeSession(savedSession);
    if (!response.success) throw new Error('Failed to resume session');
  } catch (e) {
    clearSession();
    throw e;
  }

  if (!atpClient.agent.session) throw new Error('Agent session not available');
  console.log('Session resumed. exp:', new Date(getSessionExpiryDate(atpClient.agent.session)).toLocaleString('en-US'));

  // Save the refreshed session
  saveSessionToLocalStorage();
  return atpClient.agent.session;
};
